<template>
	<screen class="new-transactions-report-view" size="large">
		<h1 slot="title">Recent RoundUps</h1>
		<template slot="buttons">
			<ui-button @click="handleDownload">Export CSV</ui-button>
		</template>
		<div slot="chart">
			<div class="chart-options">
				<el-date-picker
					v-model="date"
					type="daterange"
					start-placeholder="Start date"
					end-placeholder="End date"
					size="small"
					@change="dateChange"
				>
				</el-date-picker>
			</div>
			<chart v-if="graph" :data="graph"></chart>
		</div>
		<el-table
			slot="table"
			:data="items"
			:perPage="perPage"
			style="width: 100%"
		>
			<el-table-column prop="date" label="Date" width="125">
				<template slot-scope="scope">
					{{ parseDate(scope.row.date) }}
				</template>
			</el-table-column>
			<el-table-column v-if="id" prop="transaction_id" label="ID">
			</el-table-column>
			<el-table-column prop="amount" label="RoundUp" width="100">
				<template slot-scope="scope">
					${{ cleanupDonation(scope.row.expected_donation) }}
				</template>
			</el-table-column>
			<el-table-column label="Match">
				<template slot-scope="scope">
					${{ cleanupDonation(scope.row.match_donation) }}
					{{ multiplierDisplay(scope.row) }}
				</template>
			</el-table-column>
			<el-table-column label="Organization">
				<template slot-scope="scope">
					{{ getDemoInstitutionName(scope) }}
				</template>
			</el-table-column>
			<el-table-column prop="roundup_id" label="Status">
				<template slot-scope="scope">
					{{ scope.row.roundup_id ? 'Donated' : 'Pending Donation' }}
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@current-change="pageChange"
			:current-page="page"
			:page-size="perPage"
			layout="prev, pager, next, total"
			:total="total"
		>
		</el-pagination>
	</screen>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { get } from '@/utilities'
import table from '@/mixins/table'
import download from '@/mixins/download'
import moment from 'moment'

import UiButton from '../../ui/Button'
import Screen from '../../ui/Screen'
import Chart from '../../ui/Chart'

export default {
	name: 'new-transactions-report-view',
	components: { UiButton, Screen, Chart },
	props: {
		id: {
			type: Boolean,
			default: true
		},
		perPage: {
			type: Number,
			default: 5
		}
	},
	mixins: [table, download],
	computed: {
		...mapState({
			items: ({ reportList }) => reportList.items,
			total: ({ reportList }) => reportList.total,
			graph: ({ reportList }) => reportList.graph,
			authToken: ({ me }) => me.token
		}),
		...mapGetters(['institution', 'multiplier'])
	},
	data() {
		return {
			date: null,
			tableParamKeys: ['startDate', 'endDate'],
			demo: localStorage.getItem('demo') || false
		}
	},
	head: {
		title: {
			inner: 'Reports - New RoundUps'
		}
	},
	methods: {
		...mapActions(['getNewTransactionsReport']),
		paramsChanged(params) {
			this.getNewTransactionsReport(params)
		},
		handleDownload() {
			window.analytics.track('download_new_transaction_report')
			var params = this.getParameters()
			params.perPage = 5000
			params.page = 0
			console.log(params)
			this.postParameters(
				'/reports/new-transactions/csv',
				params,
				this.authToken
			)
		},
		dateChange(dates) {
			let params = this.getParameters()
			if (dates) {
				params.startDate = moment(dates[0]).format(
					'YYYY-MM-DD HH:mm:ss'
				)
				params.endDate = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
			}
			this.setParameters(params)
		},
		parseDate(date) {
			return moment.utc(date).format('MM/DD/YYYY')
		},
		get,
		computeRoundUp(amount, multiplier = 1) {
			if (Math.ceil(amount) - amount === 0) {
				return (1.0 * multiplier).toFixed(2)
			}
			return ((Math.ceil(amount) - amount) * multiplier).toFixed(2)
		},
		cleanupDonation(amount) {
			if (amount) {
				return amount.toFixed(2)
			}
			return 0.0
		},
		matchValue(row) {
			if (this.demo === 'true' && this.multiplier) {
				return `$${this.computeRoundUp(row.amount, this.multiplier)} (${
					this.multiplier
				}x)`
			}
			if (!row.multiplier) {
				return '$0.00'
			}
			return `$${this.computeRoundUp(row.amount, row.multiplier)} (${
				row.multiplier
			}x)`
		},
		multiplierDisplay(row) {
			if (!row.multiplier) {
				return ''
			}
			return `(${row.multiplier}x)`
		},
		getDemoInstitutionName(scope) {
			if (!scope.row.institution) return ''

			return this.demo === 'true'
				? this.institution.name
				: scope.row.institution.name
		}
	},
	mounted() {
		this.getNewTransactionsReport(this.getParameters())
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.new-transactions-report-view {
	.el-table__header-wrapper {
		position: relative;

		th {
			.cell {
				font-weight: 600;
			}
		}
	}
}
</style>
