var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen',{staticStyle:{"margin-top":"0","margin-bottom":"10px","margin-left":"10px","text-align":"center"}},[_c('h1',{staticStyle:{"text-align":"left"},attrs:{"slot":"title"},slot:"title"},[_vm._v(" Your Next Donation ")]),_c('template',{slot:"sub"},[_c('div',{staticClass:"pending-text"},[_vm._v("Pending Donation")]),_c('div',{staticClass:"pending-amount"},[_vm._v(" $"+_vm._s(( Math.round( (this.donationSums.totalRoundUp + this.donationSums.totalMatch) * 100 ) / 100 ).toFixed(2))+" ")])]),_c('div',{staticClass:"summary-line"},[_c('div',{staticClass:"pending-text"},[_vm._v("All RoundUps")]),_c('div',{staticClass:"pending-text"},[_vm._v("Pending Match")])]),_c('div',{staticClass:"summary-line"},[_c('div',{staticClass:"summary-amount"},[_vm._v(" $"+_vm._s(( Math.round(this.donationSums.totalRoundUp * 100) / 100 ).toFixed(2))+" ")]),_c('div',{staticClass:"summary-amount"},[_vm._v(" $"+_vm._s(( Math.round(this.donationSums.totalMatch * 100) / 100 ).toFixed(2))+" ")])]),_c('div',{staticClass:"org-toggle"},[_c('span',{staticClass:"org-toggle-title"},[_vm._v("My Organizations")]),_c('i',{staticClass:"fas fa-angle-up org-toggle-icon",style:(this.showOrgs ? 'transform: rotate(0deg)' : ''),on:{"click":function () { return (this$1.showOrgs = !this$1.showOrgs); }}})]),(this.showOrgs)?_c('div',{staticClass:"my-orgs-container"},[(
				this.home.pendingDonations.length === 0 &&
					!this.home.institution
			)?_c('span',{staticStyle:{"margin-top":"20px","align-self":"center","color":"#747a8d"}},[_vm._v(" No Organizations Selected ")]):_vm._e(),(
				this.home.institution &&
					this.home.pendingDonations.length === 0
			)?_c('div',{staticClass:"org-line-item"},[(this.home.institution.logo_url)?_c('img',{staticClass:"org-line-item-image",attrs:{"src":this.home.institution.logo_url}}):_c('v-icon',{staticClass:"org-line-item-icon"},[_vm._v(" fas fa-hand-holding-heart ")]),_c('div',{staticClass:"org-line-item-right"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(this.home.institution.name))])])],1):_vm._e(),_vm._l((this.home.pendingDonations),function(donation){return _c('div',{key:donation.name,staticClass:"org-line-item"},[_c('img',{staticClass:"org-line-item-image",attrs:{"src":donation.logo_url}}),_c('div',{staticClass:"org-line-item-right"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(donation.name))]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.getFirstDate(donation.first_roundUp))+" - "+_vm._s(_vm.getLastDate(donation.last_roundUp)))]),_c('span',{staticClass:"amount"},[_vm._v("$"+_vm._s(( Math.round( (donation.expected_donation + (donation.match_donation || 0)) * 100 ) / 100 ).toFixed(2)))])])])})],2):_vm._e(),_c('ui-button',{on:{"click":_vm.showDonations}},[_vm._v("Past Donations")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }