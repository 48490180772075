<template>
	<section class="institution-view-snapshot" v-if="value">
		<div class="institution-inner">
			<div class="institution-cover">
				<img v-if="inst.image_url" :src="inst.image_url" />
			</div>

			<div class="institution-header">
				<div class="header-flex">
					<img class="header-logo" :src="inst.logo_url" />
					<div class="header-text">{{ inst.name }}</div>
				</div>
				<div class="header-background">
					<img :src="inst.image_url" />
				</div>
			</div>
			<div
				v-if="showDescr"
				class="institution-description"
				style="overflow-y: auto; margin-top: 15px"
			>
				{{ inst.description }}
			</div>
			<div class="institution-button" v-if="institution.id !== inst.id">
				<ui-button @click="selectInstitution"
					>Select Recipient</ui-button
				>
			</div>
			<div class="institution-button" v-else>
				<ui-button @click="changeInstitution"
					>Change Organization</ui-button
				>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import UiButton from '../../ui/Button'

export default {
	name: 'institution-view-snapshot',
	components: { UiButton },
	props: {
		value: Object,
		showDescr: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			inst: this.value
		}
	},
	computed: {
		...mapGetters(['merchant', 'institution'])
	},
	methods: {
		...mapActions([
			'attachInstitution',
			'getInstitutionDetail',
			'getCurrentUser'
		]),
		changeInstitution() {
			this.$router.push({ name: 'select-cause' })
		},
		selectInstitution() {
			this.$swal({
				title: 'Approval Required',
				text: `By selecting ${this.inst.name}, we will notify them of your choice and request their permission to allow you to list them as your cause. We do this automatically and will notify you when they accept your request.`,
				showCancelButton: true,
				allowOutsideClick: true,
				allowEscapeKey: false
			}).then(result => {
				if (!result.value) {
					return
				}
				this.attachInstitution(this.inst.id)
					.then(() => {
						if (!this.merchant.stripe_source) {
							this.$router.push({ name: 'select-bank' })
							return
						}
						this.$router.push({ name: 'reports' })
					})
					.catch(error => {
						this.$swal(error.message)
					})
			})
		}
	},
	watch: {
		value(newValue) {
			this.inst = newValue
			this.getInstitutionDetail(newValue.id).then(data => {
				this.inst = data
			})
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.institution-view-snapshot {
	flex: 1;
	padding-top: 20px;
	max-width: 480px;
	height: 100%;
	padding-bottom: 20px;
	@media screen and (max-width: 480px) {
		padding-top: 20px;
	}
	.institution-inner {
		position: relative;
		height: 100%;
		top: 0px;
		border-radius: 20px;
		background: $white;
		width: 100%;
		padding: 30px 20px 90px;
		overflow: auto;
		margin: 0 auto;
		.institution-cover {
			position: relative;
			height: 260px;
			border-radius: 12px 12px 0px 0px;
			overflow: hidden;
			img {
				height: 100%;
				max-width: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				width: 100%;
			}
		}
		.institution-header {
			background: #000;
			position: relative;
			overflow: hidden;
			min-height: 80px;
			margin: -60px 0 0;
			border-radius: 0px 0px 12px 12px;
			.header-background {
				position: absolute;
				bottom: -20px;
				margin-left: -25px;
				margin-right: -25px;
				-webkit-filter: blur(10px);
				filter: blur(10px);
				opacity: 0.9;
				z-index: 0;
				img {
					height: 100%;
					max-width: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					width: 100%;
				}
			}
			.header-flex {
				position: relative;
				z-index: 1;
				display: flex;
				padding: 20px;
				.header-logo {
					position: relative;
					z-index: 1;
					max-width: 40px;
					max-height: 40px;
					flex-shrink: 0;
					border-radius: 6px;
				}
				.header-text {
					flex-grow: 1;
					color: $white;
					padding-left: 15px;
					font-weight: 600;
					font-size: 17px;
					display: flex;
					align-items: center;
				}
			}
		}
		.institution-title {
			font-family: 'Bitter', serif;
		}
		.institution-button {
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			padding: 20px;
			text-align: center;
			button {
				max-width: 440px;
				margin: 0 auto;
			}
		}
	}
}

.slide-up-down-enter {
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
	.institution-inner {
		transform: translate(0, 100%);
	}
}
.slide-up-down-leave-active {
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
	.institution-inner {
		transform: translate(0, 100%);
	}
}
.slide-up-down-enter-active,
.slide-up-down-leave-active,
.slide-up-down-enter-active .institution-inner,
.slide-up-down-leave-active .institution-inner {
	transition: all 250ms ease-in-out;
}
</style>
