<template>
	<screen
		style="margin-top: 0; margin-bottom: 10px"
		:bannerVariation="bannerColor"
	>
		<h1 slot="title">Multiply your impact!</h1>
		<template slot="buttons">
			<button
				type="button"
				class="button--blank"
				@click="openMultiplierModal"
			>
				<i class="far fa-question-circle question-icon" />
			</button>
			<modal v-model="multiplierModal">
				<h2 class="multiplierModal-header">Donation Multiplier</h2>

				<p>
					Add a donation multiplier to match a percentage of every
					customer's donation and make a bigger impact for your
					nonprofits.
				</p>
				<p>
					When enabled, every customer's donation will be multiplied
					by the amount set on the slider, and your tax-deductible
					donation will make-up the difference.
				</p>
				<p>
					Use the slider to set the multiplier value and see the
					sample impact in the Donation Example box.
				</p>
				<p>
					Toggle it on to start multiplying donations immediately.
					Toggle off at any time to stop.
				</p>
				<p>Your donation will be processed at the end of the month.</p>

				<div class="multiplierModal-footer">
					<i class="far fa-chart-bar"></i>
					<div>
						"84% of survey participants say they’re more likely to
						donate if a match is offered."
						<em>Source: The Big-Give Research Initiative</em>
					</div>
				</div>
			</modal>
		</template>
		<template slot="banner">
			<div>Add {{ computeMultiplier }}x donation multiplier</div>
			<ui-toggle @input="multiplierToggle" :checked="multiplierEnabled" />
		</template>
		<template slot="sub">
			Make a bigger impact and encourage more donations by matching or
			multiplying every customer's donation.
		</template>
		<template slot="content">
			<range-slider
				ref="slider"
				suffix="x"
				:options="[1, 2, 3, 4, 5, 10, 20]"
				v-model="this.multiplier"
				v-bind:initialValue="this.initialMultiplier"
				@change="sliderChange($event)"
			/>
			<div
				class="multiplier-confirm"
				v-if="this.multiplierEnabled && this.multiplierDirty"
			>
				<ui-button color="border" v-on:click="handleResetForm"
					>Cancel</ui-button
				>
				<ui-button :loading="isLoading" v-on:click="handleSetMultiplier"
					>Confirm</ui-button
				>
			</div>
			<aside class="multiplierDemo">
				<div class="multiplierDemo-title">Donation Example</div>
				<div class="multiplierDemo-ui">
					<header class="multiplierDemo-header">
						<div class="multiplierDemo-header-title">
							Total donation
						</div>
						<div class="multiplierDemo-header-total">
							${{ totalAmount }}
						</div>
					</header>
					<div class="multiplierDemo-controls">
						<div>
							<div class="multiplierDemo-controls-label">
								Customer donation
							</div>
							<div class="inputPrefix-wrapper">
								<div class="inputPrefix-content">$</div>
								<ui-input
									v-model="amount"
									type="number"
									variation="border"
									@input="upateMultiplierAmounts($event)"
									size="small"
								/>
							</div>
						</div>
						<div>
							<div class="multiplierDemo-controls-label">
								Your donation
							</div>
							<div class="inputPrefix-wrapper">
								<div class="inputPrefix-content">$</div>
								<ui-input
									v-model="yourAmount"
									type="number"
									variation="blank"
									size="small"
									readonly
								/>
							</div>
						</div>
					</div>
				</div>
			</aside>
			<div class="match-section">
				<span
					class="addMax"
					v-on:click="showMaxModal = true"
					v-if="!this.merchant.max_match_amount"
				>
					Add a monthly maximum</span
				>
				<div v-if="this.merchant.max_match_amount" class="editMax">
					<div class="top">
						<span>You will match up to</span>
						<i
							class="far fa-edit"
							v-on:click="showMaxModal = true"
						></i>
					</div>
					<div class="bottom">
						<span class="max-amount"
							>{{ formattedCurrency }}/month</span
						>
					</div>
					<div class="max-reached" v-if="matchPaused">
						You’ve reached your monthly max. Multiplying will resume
						next month.
					</div>
				</div>
			</div>
		</template>
		<modal v-model="showMaxModal" size="medium">
			<div class="max-title-container">
				<span class="max-title">Monthly Maximum</span>
			</div>
			<span class="max-content">
				Stop multiplying customer donations each month when the total
				exceeds your set maximum amount.
			</span>
			<div
				class="pending-warning"
				v-if="this.donationSums.totalMatch > 0"
			>
				<div class="icon-circle">
					<i class="fas fa-dollar-sign" style="color: white"></i>
				</div>
				Current Pending Match:
				<b>${{ this.donationSums.totalMatch.toFixed(2) }}</b>
			</div>
			<ui-label>
				<ui-input
					v-model="initialMaxMatch"
					@input="updateMaxMatch($event)"
					placeholder="$ Monthly Match Maximum"
					style="width: 250px"
				/>
			</ui-label>
			<ui-button class="mr-5" :loading="isLoading" v-on:click="updateMax"
				>Save</ui-button
			>
			<ui-button color="border" v-on:click="cancelMaxChange"
				>Cancel</ui-button
			>
			<div class="remove-max" v-if="this.merchant.max_match_amount">
				<span v-on:click="removeMax"> Remove maximum </span>
			</div>
		</modal>
	</screen>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { dollarInputFormat } from '@/utilities'
import Screen from '../../ui/Screen.vue'
import UiToggle from '../../ui/Toggle'
import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import Modal from '../../ui/Modal'
import RangeSlider from '../../ui/RangeSlider'

export default {
	name: 'multiplier-tile-interactive',
	components: {
		Screen,
		UiToggle,
		UiButton,
		UiLabel,
		UiInput,
		Modal,
		RangeSlider
	},
	data() {
		return {
			multiplierDirty: false,
			amount: 1,
			yourAmount: 0,
			totalAmount: 0,
			initialMultiplier: 0,
			multiplier: 0,
			multiplierEnabled: false,
			isLoading: false,
			multiplierModal: false,
			showMaxModal: false,
			initialMaxMatch: null
		}
	},
	computed: {
		...mapState({
			home: ({ merchant }) => merchant.home
		}),
		...mapGetters(['merchant', 'donationSums', 'matchPaused']),
		computeMultiplier() {
			if (this.multiplierEnabled) {
				return this.initialMultiplier
			}
			return this.multiplier
		},
		formattedCurrency() {
			// const x = dollarInputFormat(this.merchant.max_match_amount / 100)
			return dollarInputFormat(this.merchant.max_match_amount / 100)
		},
		bannerColor() {
			if (!this.merchant.multiplier || this.merchant.multiplier === 0) {
				return ''
			}
			if (this.matchPaused) {
				return 'error'
			}
			return 'primary'
		}
	},
	methods: {
		...mapActions(['update']),
		multiplierToggle() {
			this.multiplierEnabled = !this.multiplierEnabled

			// Fire save action onChange
			this.handleSetMultiplier()
		},
		upateMultiplierAmounts(evt) {
			this.yourAmount = (
				parseFloat(evt) * parseFloat(this.multiplier)
			).toFixed(2)
			this.totalAmount = (
				parseFloat(evt) + parseFloat(this.yourAmount)
			).toFixed(2)
		},
		updateMaxMatch(evt) {
			this.initialMaxMatch = dollarInputFormat(evt)
		},
		sliderChange(val) {
			this.multiplier = val

			this.upateMultiplierAmounts(this.amount)

			this.multiplierDirty = true
		},
		handleResetForm() {
			this.multiplier = this.initialMultiplier

			// Also update value of slider based on recommendations from package
			this.$refs.slider.value = this.initialMultiplier

			this.multiplierDirty = false
		},
		handleSetMultiplier() {
			this.isLoading = true

			// If the toggle-state is enabled, we can send through the currently selected value; otherwise send 0 to disable
			this.update({
				multiplier: this.multiplierEnabled ? this.multiplier : 0
			})
				.then(() => {
					this.isLoading = false
					this.multiplierDirty = false

					if (this.multiplierEnabled) {
						this.$swal({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 3000,
							title: 'Impact multiplied',
							text: `You are now multiplying every customer's RoundUps by ${this.multiplier}x.`
						})
					} else {
						this.$swal({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 3000,
							title: 'Multiplier disabled',
							text: `You are no longer multiplying customer RoundUps.`
						})
					}

					window.analytics.track('merchant_updated_multiplier', {
						source: 'web'
					})

					// Reset initial values if form is edited again in the same session
					this.initialMultiplier = this.multiplier
				})
				.catch(({ message }) => {
					this.isLoading = false
					this.$swal('Error', message)
				})
		},
		openMultiplierModal() {
			this.multiplierModal = true
		},
		updateMax() {
			const maxMatch = this.initialMaxMatch.replace(/[^\d.-]/g, '')
			// we do not want to allow 0 or negative numbers
			if (maxMatch <= 0) {
				this.$swal({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000,
					title: 'UNABLE TO UPDATE',
					text: 'The monthly maximum must be at least $1'
				})
				return
			}
			// if we have any pending donations we need to NOT allow them to set the match to less than that
			const pause = this.donationSums.totalMatch >= maxMatch
			const title = pause ? 'MONTHLY MAXIMUM EXCEEDED' : 'SUCCESS'
			const text = pause
				? 'Your pending match is higher than your max. Multiplying is paused and your new maximum will apply next month.'
				: 'Your monthly maximum has been updated.'
			this.update({ max_match_amount: maxMatch * 100 }).then(() => {
				this.showMaxModal = false
				this.$swal({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000,
					title: title,
					text: text
				})
			})
		},
		removeMax() {
			this.initialMaxMatch = null
			this.update({ max_match_amount: null }).then(() => {
				this.showMaxModal = false
				this.$swal({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000,
					title: 'SUCCESS',
					text: `Your monthly maximum has been removed.`
				})
			})
		},
		cancelMaxChange() {
			this.showMaxModal = false
			this.initialMaxMatch = this.merchant.max_match_amount / 100
		}
	},
	mounted() {
		// @NOTE Setting initial and maluable value so we can keep track and rest as needed
		const initialMultiplierValue = this.merchant.multiplier
			? this.merchant.multiplier
			: 2
		// @NOTE 2 is recommended value as fallback
		this.initialMultiplier = initialMultiplierValue
		this.multiplier = initialMultiplierValue

		// Also update value of slider based on recommendations from package
		this.$refs.slider.value = initialMultiplierValue

		this.multiplierEnabled = Boolean(this.merchant.multiplier)

		// Fire off initial amount calculation
		this.upateMultiplierAmounts(this.amount)

		// only set the initial match to this if max match is > 0, that way the input is null and shows the placeholder
		if (this.merchant.max_match_amount > 0) {
			this.initialMaxMatch = dollarInputFormat(
				this.merchant.max_match_amount &&
					this.merchant.max_match_amount / 100
			)
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.screen {
	.screen-header {
		align-items: center;
		.screen-title {
			h1 {
				margin-top: 0;
			}
		}
	}
	.screen-buttons {
		display: flex;
	}
}
.button--blank {
	cursor: pointer;
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	color: inherit;
	font: inherit;
	line-height: 1;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
.question-icon {
	font-size: 20px;
	color: $roundup-medium-grey;
}
.multiplierModal-header {
	margin-top: 0;
}
.multiplierModal-footer {
	margin-top: 30px;
	display: grid;
	border-radius: 8px;
	grid-auto-flow: column;
	grid-auto-columns: auto;
	justify-items: start;
	gap: 10px;
	background: $roundup-cerulean;
	padding: 10px 20px;
	color: $white;
	i {
		font-size: 20px;
		margin-top: 5px;
	}
}
.screen-banner {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: auto;
	gap: 10px;
	align-items: center;
	.roundup-toggle {
		justify-self: end;
	}
}
.multiplier-confirm {
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	justify-content: end;
	gap: 10px;
	margin-bottom: 20px;
}
.multiplierDemo {
	margin-top: 20px;
	.roundup-input {
		input {
			color: inherit;
		}
	}
}
.multiplierDemo-title {
	font-size: 14px;
	color: $roundup-medium-grey;
	font-weight: bold;
	margin-bottom: 5px;
}
.multiplierDemo-ui {
	border-radius: 8px;
	border: 2px solid $roundup-mint;
	padding: 10px;
}
.multiplierDemo-header {
	margin-bottom: 20px;
	text-align: center;
}
.multiplierDemo-header-title {
	font-size: 12px;
	color: $roundup-medium-grey;
}
.multiplierDemo-header-total {
	font-size: 32px;
}
.multiplierDemo-controls {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	gap: 20px;
}
.multiplierDemo-controls-label {
	font-size: 12px;
	color: $roundup-medium-grey;
	display: block;
	margin-bottom: 5px;
}
.inputPrefix-wrapper {
	position: relative;
	input {
		padding-left: 15px !important;
	}
}
.inputPrefix-content {
	position: absolute;
	font-size: 14px;
	top: calc(50% - 1.25px);
	padding-left: 5px;
	transform: translateY(-50%);
	pointer-events: none;
}
.match-section {
	margin-top: 20px;
	.addMax {
		font-size: 16px;
		color: #2553cd;
		line-height: 20px;
		text-decoration: underline;
		cursor: pointer;
	}
	.editMax {
		div {
			&.top {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				span {
					font-size: 12px;
					line-height: 18px;
					color: #747a8d;
					font-weight: 400;
				}
				i {
					cursor: pointer;
				}
			}
			&.bottom {
				span {
					&.max-amount {
						font-size: 25px;
						line-height: 30px;
						color: #484a50;
						font-weight: 400;
					}
				}
			}
			&.max-reached {
				background-color: $roundup-light-red;
				margin-top: 20px;
				padding: 10px;
				font-size: 16px;
				line-height: 20px;
				color: #484a50;
				font-weight: 400;
				border-radius: 12px;
			}
		}
	}
}
div {
	&.max-title-container {
		margin-top: 10px;
		margin-bottom: 10px;
		.max-title {
			font-size: 20px;
			font-weight: 400;
			color: #484a50;
		}
	}
	&.remove-max {
		margin-top: 20px;
		span {
			font-size: 16px;
			color: #2553cd;
			line-height: 20px;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
.max-content {
	line-height: 20px;
	font-size: 16px;
	color: #484a50;
	font-weight: 400;
}
.pending-warning {
	background-color: #d9fdf5;
	height: 40px;
	width: 100%;
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-left: 10px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	color: #07745c;
	border-radius: 5px;
	div {
		&.icon-circle {
			background-color: #03755b;
			height: 24px;
			width: 24px;
			border-radius: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 5px;
		}
	}
	b {
		padding-left: 5px;
	}
}
.max-input {
	margin-top: 20px;
	width: 240px;
}
</style>
