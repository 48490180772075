<template>
	<ul class="segmented-control">
		<li
			v-for="option in options"
			:key="option"
			class="segmented-control__item"
		>
			<input
				class="segmented-control__input"
				type="radio"
				:value="option"
				:checked="option == value"
				@change="$emit('on-change', option)"
				:name="name"
				:id="'option-' + option + '-' + name"
			/>
			<label
				v-if="option !== 'custom'"
				class="segmented-control__label"
				:for="'option-' + option + '-' + name"
				>{{ labelPrefix }}{{ labelParse(option)
				}}{{ labelSuffix }}</label
			>
			<label
				v-else
				class="segmented-control__label"
				:for="'option-' + option + '-' + name"
				>{{ option }}</label
			>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'segment-toggle',
	props: {
		value: {},
		options: {
			type: Array
		},
		name: {
			type: String,
			default:
				Math.random()
					.toString(36)
					.substring(2, 15) +
				Math.random()
					.toString(36)
					.substring(2, 15)
		},
		labelPrefix: {
			type: String,
			default: ''
		},
		labelSuffix: {
			type: String,
			default: ''
		},
		labelParse: {
			type: Function,
			default: val => val
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.segmented-control {
	background: $roundup-navy;
	display: flex;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 0px;
	padding: 0;
	border-radius: 25px;
	.segmented-control__item {
		flex-grow: 1;
		flex-basis: 0;
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	.segmented-control__input {
		position: absolute;
		visibility: hidden;
		&:checked {
			& + .segmented-control__label {
				background: $white;
				color: $roundup-navy-highlight;
				border: 1px solid $roundup-navy;
			}
		}
	}
	.segmented-control__label {
		display: block;
		padding: 15px 10px;
		text-align: center;
		cursor: pointer;
		border-radius: 25px;
		font-weight: bold;
		&:hover {
			background: $roundup-navy-highlight;
			color: $white;
		}
	}
}
</style>
