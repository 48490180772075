var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roundup-dashboard"},[_c('div',{staticClass:"roundup-row"},[_c('div',{staticStyle:{"flex":"2"}},[_c('transactions',{attrs:{"id":false}}),(_vm.transactions.length < 10)?_c('div',{staticClass:"roundup-row"},[_c('multiplier-tile-interactive',{staticStyle:{"flex":"1"}}),_c('last-donation',{staticStyle:{"flex":"1","align-self":"flex-start"}})],1):_vm._e()],1),_c('div',{staticStyle:{"flex":"1"}},[_c('div',[_c('card',[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Current Snapshot")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"value",style:({
								alignItems: 'center',
								textAlign: 'center',
								marginBottom: '15px'
							})},[_c('strong',{style:({ fontSize: '36px' })},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.home.donatedYTD)))]),_c('span',[_vm._v(" Donated YTD")])]),_c('div',{staticClass:"roundup-row",style:({ justifyContent: 'space-around' })},[_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.home.newRoundUps > 0 ? '+' + _vm.home.newRoundUps .toString() .replace( /\B(?=(\d{3})+(?!\d))/g, ',' ) : 0))]),_c('span',[_vm._v(" RoundUps this week")])]),_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.home.totalRoundUps .toString() .replace( /\B(?=(\d{3})+(?!\d))/g, ',' )))]),_c('span',[_vm._v(" total RoundUps")])])]),_c('div',{style:({
								marginTop: '15px',
								textAlign: 'center'
							})},[_c('router-link',{attrs:{"to":{ name: 'reports' }}},[_vm._v("View Detailed Reports")])],1)])]),_c('institution-snapshot',{staticStyle:{"width":"100%","padding":"0px 10px 20px 10px","max-width":"600px"},attrs:{"showDescr":_vm.transactions.length < 10},model:{value:(_vm.home.institution),callback:function ($$v) {_vm.$set(_vm.home, "institution", $$v)},expression:"home.institution"}}),(_vm.transactions.length >= 10)?_c('div',{staticStyle:{"width":"100%","padding":"0px 10px 20px 10px","max-width":"600px"}},[_c('last-donation',{staticStyle:{"margin-left":"0"}}),_c('multiplier-tile-interactive',{staticStyle:{"flex":"1","margin-left":"0"}})],1):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }