<template>
	<div class="range-wrapper">
		<vue-slider
			ref="slider"
			v-model="value"
			v-on:callback="$emit('change', $event)"
			height="12"
			:data="options"
			:dotSize="24"
			:processStyle="{ backgroundColor: '#2553CD' }"
			:tooltipStyle="{
				backgroundColor: '#2553CD',
				borderColor: '#2553CD'
			}"
		/>
		<div class="range-footer">
			<div>{{ Math.min(...options) }}{{ suffix }}</div>
			<div>{{ Math.max(...options) }}{{ suffix }}</div>
		</div>
	</div>
</template>

<script>
import vueSlider from 'vue-slider-component'

export default {
	data: () => {
		return {
			value: this.initialValue
		}
	},
	props: {
		initialValue: {
			type: Number,
			default: 2
		},
		interval: {
			type: Number,
			default: 1
		},
		min: {
			type: Number,
			default: null
		},
		max: {
			type: Number,
			default: null
		},
		suffix: {
			type: String,
			default: ''
		},
		options: {
			type: Object,
			default: () => [1, 2, 3, 4, 5]
		}
	},
	components: {
		vueSlider
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.vue-slider-component .vue-slider {
	background: $roundup-light-grey;
}
.vue-slider-component .vue-slider-tooltip {
	background: $roundup-navy;
	color: $white;
	padding: 2px 5px;
	border: 0;
	font-weight: bold;
	position: relative;
	&::before {
		display: none;
	}
	&::after {
		content: 'x';
	}
}
.vue-slider-component .vue-slider-process {
	background-color: $roundup-navy;
}
.range-wrapper {
	margin-top: 40px;
}
.range-footer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	margin-top: 5px;
	font-size: 16px;
	color: $roundup-medium-grey;
	& > *:last-of-type {
		justify-self: end;
	}
}
.vue-slider-component .vue-slider {
	background: $roundup-light-grey;
}
.vue-slider-component .vue-slider-tooltip {
	background: $roundup-navy;
	color: $white;
	padding: 2px 5px;
	border: 0;
	font-weight: bold;
	position: relative;
	&::before {
		display: none;
	}
	&::after {
		content: 'x';
	}
}
.vue-slider-component .vue-slider-process {
	background-color: $roundup-navy;
}
.range-wrapper {
	margin-top: 40px;
}
.range-footer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	margin-top: 5px;
	font-size: 16px;
	color: $roundup-medium-grey;
	& > *:last-of-type {
		justify-self: end;
	}
}
</style>
