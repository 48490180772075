<template>
	<home-tile
		color="cerulean"
		button-title="Set Match"
		:loading="isLoading"
		@click="handleSetMultiplier"
	>
		<span class="tile-text-small">
			Match your consumer's impact!<br />{{
				multiplier
					? `You are donating ${multiplier}x your customer's change.`
					: "You are not matching your custome's change"
			}}
		</span>
		<div class="multiplier-options">
			<segment-toggle
				:value="multiplier"
				:options="options"
				name="multiplier"
				@on-change="updateMultiplier"
				labelSuffix="x"
			/>
		</div>
	</home-tile>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import HomeTile from '../../ui/Tile.vue'
import SegmentToggle from '../../ui/SegmentToggle'

export default {
	name: 'multiplier-tile',
	components: { HomeTile, SegmentToggle },
	data() {
		return {
			options: [0, 1, 1.25, 1.5, 2],
			multiplier: 1,
			isLoading: false
		}
	},
	computed: {
		...mapGetters(['merchant'])
	},
	methods: {
		...mapActions(['update']),
		updateMultiplier(val) {
			this.multiplier = val
		},
		handleSetMultiplier() {
			this.isLoading = true
			this.update({ multiplier: this.multiplier })
				.then(() => {
					this.isLoading = false
					this.$swal(
						'Success',
						'Your multiplier has been set! Your next donation will increase by this multiplier!'
					)
				})
				.catch(({ message }) => {
					this.isLoading = false
					this.$swal('Error', message)
				})
		}
	},
	mounted() {
		this.multiplier = this.merchant.multiplier
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.segmented-control {
	background: $roundup-navy;
	display: flex;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 0px;
	padding: 0;
	border-radius: 25px;
	.segmented-control__item {
		flex-grow: 1;
		flex-basis: 0;
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	.segmented-control__input {
		position: absolute;
		visibility: hidden;
		&:checked {
			& + .segmented-control__label {
				background: $white;
				color: $roundup-navy-highlight;
			}
		}
	}
	.segmented-control__label {
		display: block;
		padding: 15px 10px;
		text-align: center;
		cursor: pointer;
		border-radius: 25px;
		font-weight: bold;
		&:hover {
			background: $roundup-navy-highlight;
			color: $white;
		}
	}
}
</style>
