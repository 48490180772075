<template>
	<div class="roundup-dashboard">
		<div class="roundup-row">
			<div style="flex: 2">
				<transactions v-bind:id="false" />
				<div class="roundup-row" v-if="transactions.length < 10">
					<multiplier-tile-interactive style="flex: 1" />
					<last-donation style="flex: 1; align-self: flex-start" />
				</div>
			</div>
			<div style="flex: 1">
				<div>
					<card>
						<h1 slot="title">Current Snapshot</h1>
						<div slot="body">
							<div
								class="value"
								:style="{
									alignItems: 'center',
									textAlign: 'center',
									marginBottom: '15px'
								}"
							>
								<strong :style="{ fontSize: '36px' }">
									{{ numberFormat(home.donatedYTD) }}</strong
								>
								<span> Donated YTD</span>
							</div>
							<div
								class="roundup-row"
								:style="{ justifyContent: 'space-around' }"
							>
								<div class="value">
									<strong>{{
										home.newRoundUps > 0
											? '+' +
											  home.newRoundUps
													.toString()
													.replace(
														/\B(?=(\d{3})+(?!\d))/g,
														','
													)
											: 0
									}}</strong>
									<span> RoundUps this week</span>
								</div>
								<div class="value">
									<strong>{{
										home.totalRoundUps
											.toString()
											.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
									}}</strong>
									<span> total RoundUps</span>
								</div>
							</div>
							<div
								:style="{
									marginTop: '15px',
									textAlign: 'center'
								}"
							>
								<router-link :to="{ name: 'reports' }"
									>View Detailed Reports</router-link
								>
							</div>
						</div>
					</card>
					<institution-snapshot
						v-model="home.institution"
						v-bind:showDescr="transactions.length < 10"
						style="
							width: 100%;
							padding: 0px 10px 20px 10px;
							max-width: 600px;
						"
					/>
					<div
						v-if="transactions.length >= 10"
						style="
							width: 100%;
							padding: 0px 10px 20px 10px;
							max-width: 600px;
						"
					>
						<last-donation style="margin-left: 0" />
						<multiplier-tile-interactive
							style="flex: 1; margin-left: 0"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { mapActions, mapState } from 'vuex'
import { numberFormat } from '../../../utilities'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import Card from '../../ui/Card'
import table from '@/mixins/table'
import UiButton from '../../ui/Button'
import BlogPost from '../../ui/BlogPost'
import moment from 'moment-timezone'
import Transactions from '../Reports/NewTransactionsReportView'
import MultiplierTile from './MultiplierTile'
import MultiplierTileInteractive from './MultiplierTileInteractive'
import LastDonation from './LastDonation'
import InstitutionSnapshot from '../Institution/InstitutionSnapshot'

export default {
	name: 'home-view',
	components: {
		Screen,
		UiButton,
		UiLabel,
		BlogPost,
		Datepicker,
		Card,
		table,
		Transactions,
		MultiplierTile,
		MultiplierTileInteractive,
		InstitutionSnapshot,
		LastDonation
	},
	data() {
		return {
			isLoading: false,
			blog: [],
			countdown: 'Loading...',
			timerInterval: null,
			timePassed: 0
		}
	},
	computed: {
		...mapState({
			home: ({ merchant }) => merchant.home,
			transactions: ({ reportList }) => reportList.items
		})
	},
	methods: {
		...mapActions(['getNewTransactionsReport', 'getHome']),
		getImage(text) {
			const start = text.search('<img src="')
			const end = text.indexOf('"', start + 10)
			var figureUrl = text.substring(start + 10, end)
			if (figureUrl.indexOf('http') > -1) {
				return figureUrl
			}
			return null
		},
		getContent(text) {
			const end = text.search('Continued</a></p>')
			return text.substring(0, end) + 'Continued</a></p>'
		},
		download(row) {
			window.open(row.link, '_blank')
		},
		onUpdateLaunch() {
			this.institution.launch_date = moment(this.institution.launch_date)
				.tz('UTC')
				.format('YYYY-MM-DD')
			this.updateInstitution(this.institution)
		},
		numberFormat
	},
	mounted() {
		this.isLoading = true
		this.getNewTransactionsReport()
		this.getHome()
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.roundup-dashboard {
	.roundup-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		max-width: 100%;
	}
	.blog {
		max-height: 750px;
		overflow-y: scroll;
		position: relative;
	}
}
</style>
