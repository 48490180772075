<template>
	<screen
		style="
			margin-top: 0;
			margin-bottom: 10px;
			margin-left: 10px;
			text-align: center;
		"
	>
		<h1 slot="title" style="text-align: left">
			Your Next Donation
		</h1>
		<template slot="sub">
			<div class="pending-text">Pending Donation</div>
			<div class="pending-amount">
				${{
					(
						Math.round(
							(this.donationSums.totalRoundUp +
								this.donationSums.totalMatch) *
								100
						) / 100
					).toFixed(2)
				}}
			</div>
		</template>
		<div class="summary-line">
			<div class="pending-text">All RoundUps</div>
			<div class="pending-text">Pending Match</div>
		</div>
		<div class="summary-line">
			<div class="summary-amount">
				${{
					(
						Math.round(this.donationSums.totalRoundUp * 100) / 100
					).toFixed(2)
				}}
			</div>
			<div class="summary-amount">
				${{
					(
						Math.round(this.donationSums.totalMatch * 100) / 100
					).toFixed(2)
				}}
			</div>
		</div>
		<div class="org-toggle">
			<span class="org-toggle-title">My Organizations</span>
			<i
				class="fas fa-angle-up org-toggle-icon"
				v-bind:style="this.showOrgs ? 'transform: rotate(0deg)' : ''"
				@click="() => (this.showOrgs = !this.showOrgs)"
			/>
		</div>

		<div v-if="this.showOrgs" class="my-orgs-container">
			<span
				v-if="
					this.home.pendingDonations.length === 0 &&
						!this.home.institution
				"
				style="margin-top: 20px; align-self: center; color: #747a8d"
			>
				No Organizations Selected
			</span>
			<div
				v-if="
					this.home.institution &&
						this.home.pendingDonations.length === 0
				"
				class="org-line-item"
			>
				<img
					v-if="this.home.institution.logo_url"
					:src="this.home.institution.logo_url"
					class="org-line-item-image"
				/>
				<v-icon v-else class="org-line-item-icon">
					fas fa-hand-holding-heart
				</v-icon>
				<div class="org-line-item-right">
					<span class="name">{{ this.home.institution.name }}</span>
				</div>
			</div>
			<div
				v-for="donation in this.home.pendingDonations"
				:key="donation.name"
				class="org-line-item"
			>
				<img :src="donation.logo_url" class="org-line-item-image" />
				<div class="org-line-item-right">
					<span class="name">{{ donation.name }}</span>
					<span class="date"
						>{{ getFirstDate(donation.first_roundUp) }} -
						{{ getLastDate(donation.last_roundUp) }}</span
					>
					<span class="amount"
						>${{
							(
								Math.round(
									(donation.expected_donation +
										(donation.match_donation || 0)) *
										100
								) / 100
							).toFixed(2)
						}}</span
					>
				</div>
			</div>
		</div>
		<ui-button v-on:click="showDonations">Past Donations</ui-button>
	</screen>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'

import Screen from '../../ui/Screen.vue'
import UiButton from '../../ui/Button'

export default {
	name: 'last-donate-tile',
	components: { Screen, UiButton },
	data() {
		return {
			showOrgs: false
		}
	},
	computed: {
		...mapState({
			home: ({ merchant }) => merchant.home,
			donationSums: ({ donationSums }) => donationSums.donationSums
		}),
		...mapGetters(['donationSums'])
	},
	methods: {
		...mapActions(['getHome']),
		showDonations() {
			this.$router.push({ name: 'reports.donations' })
		},
		getFirstDate(date) {
			return moment(date)
				.tz('UTC')
				.format('M/D/YYYY')
		},
		getLastDate(date) {
			if (moment(date).diff(moment(new Date()), 'days') === 0) {
				return 'today'
			}
			return moment(date)
				.tz('UTC')
				.format('M/D/YYYY')
		}
	},
	mounted() {
		this.getHome()
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.screen {
	width: 100%;
	.pending-text {
		font-size: 12px;
		color: $roundup-medium-grey;
	}
	.pending-amount {
		font-size: 48px;
		font-weight: 400;
	}
	div {
		&.summary-line {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			margin-top: 12px;
			.summary-amount {
				font-size: 25px;
				font-weight: 400;
			}
		}
	}
	.donation-text {
		font-size: 12px;
		color: $roundup-medium-grey;
		margin-top: 12px;
	}
	.screen-buttons {
		display: flex;
	}
	.org-toggle {
		background-color: #f5f6f8;
		width: 100%;
		height: 44px;
		margin-top: 15px;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: -20px;
		width: calc(100% + 40px);
		.org-toggle-tittle {
			font-size: 14px;
			font-weight: 400;
			color: #484a50;
		}
		.org-toggle-icon {
			font-size: 20px;
			cursor: pointer;
			transform: rotate(180deg);
		}
	}
	.my-orgs-container {
		min-height: 115px;
		min-height: 115px;
		border-bottom: #f2f4f8;
		border-bottom-width: 2px;
		border-bottom-style: solid;
		margin-left: -20px;
		width: calc(100% + 40px);
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
		.org-line-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: 20px;
			justify-content: flex-start;
			margin-top: 20px;
			.org-line-item-image {
				height: 75px;
				width: 75px;
				border: 1px solid #747a8d;
			}
			.org-line-item-icon {
				font-size: 54px;
				border: 1px solid #f6f7f9;
				padding: 5px;
				border-radius: 5px;
				color: #9bb8ff;
			}
			.org-line-item-right {
				height: 75px;
				margin-left: 14px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
	.name {
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 220px;
		text-align: start;
	}
	.date {
		font-size: 12px;
		font-weight: 400;
		color: #747a8d;
		margin-top: 2px;
	}
	.amount {
		font-size: 25px;
		font-weight: 400;
		color: #484a50;
	}
}
</style>
