<template>
	<div
		class="home-tile"
		:class="[color ? 'tile-color--' + color : '', banner ? 'banner' : '']"
	>
		<div v-if="banner" class="banner-body">
			{{ bannerMessage }}
		</div>
		<div class="tile-text">
			<slot />
		</div>
		<div class="tile-action" v-if="buttonTitle">
			<slot name="button">
				<ui-button
					:loading="loading"
					:color="color"
					@click="$emit('click')"
					>{{ buttonTitle }}</ui-button
				>
			</slot>
		</div>
	</div>
</template>

<script>
import UiButton from './Button'

export default {
	name: 'home-view',
	props: ['color', 'buttonTitle', 'banner', 'bannerMessage', 'loading'],
	components: { UiButton },
	data() {
		return {}
	},
	methods: {}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.home-tile {
	width: 100%;
	border-radius: 20px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 10px;
	margin-bottom: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	color: $white;
	text-align: center;
	&.tile-color--mint {
		background: $roundup-mint;
	}
	&.tile-color--navy {
		background: $roundup-navy;
	}
	&.tile-color--cerulean {
		background: $roundup-cerulean;
	}
	&.tile-color--pink {
		background: $roundup-red;
	}
	&.tile-color--purple {
		background: $roundup-purple;
	}
	&.tile-color--danger {
		background: $roundup-red;
	}
	&.tile-color--white {
		background: $white;
	}
	.tile-text {
		padding: 25px 0;
	}
	.tile-header {
		text-align: center;
	}
	.tile-image {
		margin-top: -35px;
	}
	span {
		display: block;
		color: $white;
		text-align: center;
		&.tile-text-small {
			font-size: $font-size;
		}
		&.tile-text-large {
			font-size: 48px;
		}
	}
	.banner {
		padding-top: 2px;
		padding-left: 2px;
		padding-right: 2px;
	}
	.banner-body {
		background: #fff;
		color: $roundup-red;
		border-radius: 12px;
		padding: 5px;
	}
}
</style>
